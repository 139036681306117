<template>
    <div>
        <span @click="closeToken(token)"
            class="my-search-token-v2"
            v-for="token in tokens">
				<button type="button" class="close">&times;</button>
				{{token.label}}<span v-show="token.label && token.value">:</span> {{token.value}}

        </span>

    </div>
</template>

<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'search-filter-tokens-v2',
    	props: {
    		searchData: Object,
    		searchFields: Object
    	},
    	data: function() {
    		return {
    			tokens: []
    		};
    	},
    	methods: {
    		closeToken: function(token) {
    			for (let i = 0; i < this.tokens.length; i++) {
    				if (this.tokens[i] === token) {
    					let k = this.tokens[i].id;
    					this.searchData[k] = '';
    					V.log("deleting token", k);

    					if (k === 'from') {
    						this.searchData.till = undefined;
    						this.searchData.nights_max = undefined;
    						this.searchData.nights_min = undefined;
    					} else if (k === 'till') {
    						this.searchData.from = undefined;
    						this.searchData.nights_max = undefined;
    						this.searchData.nights_min = undefined;
    					} else if (k === 'pets') {
    						this.searchData.petsCount = undefined;
    					}
    					this.tokens.splice(i);

    					this.$nextTick(function() {
    						this.$emit('updated');
    					});
    					break;

    				}
    			}

    		},
    		update: function(nv) {

    			nv = nv || this.searchData;

    			this.tokens = [];

    			var field, value, v;

    			for (let k in nv) {
    				v = nv[k];

    				field = this.searchFields[k];
    				if (field && v && (field.label === 'Lage' || v !== '0') && v !== 'false' && k !== 'region') { //!_.isBlank(v)


    					value = null;

    					if (k === 'from' || k === 'till') {
    						value = DateEx.formatDate(v);

    					} else if (v !== true && v !== 'true' && k !== 'ids') {
    						//show value
    						if (field.options) {
    							value = field.options[v + ''];
    						} else {
    							value = v;
    						}
    					}

    					this.tokens.push({
    						id: k,
    						label: field.label,
    						value: value
    					});

    				}

    			}

    		}
    	}
    };
</script>

<style scoped>
</style>