<template>
    <div class="">

        <span class="price-start"
            v-html="getPriceText"></span>

    </div>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'minrent',
    	props: {
    		pricelist: Array,
    		minrent: Number
    	},
    	data: function() {
    		return {
    			totalStars: 5
    		};
    	},
    	computed: {
    		getPriceText: function() {
    			var priceText = '';
    			var minrentWeek = 0;
    			var priceCleaning = 0;
    			var priceService = 0;
    			var total = this.minrent;

    			//	console.log('Minrent::', this.minrent);

    			//	console.log('Pricelist::', this.pricelist);


    			if (this.minrent && this.pricelist) {

    				//priceText += 'minrent:' + minrentWeek;


    				for (var i = 0; i < this.pricelist.length; i++) {
    					if (this.pricelist[i].service === 43080) {
    						priceService = this.pricelist[i].value;
    						//	priceText += ' Service 38897:' + this.pricelist[i].value;

    						total = this.minrent + priceService;
    					}
    				}
    				priceText += ' ab ';
    				priceText += Number(total / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";

    			}

    			return priceText;
    		},
    		getMinRentText: function() {
    			var minRentText = '';

    			if (this.minrent) {
    				minRentText += Number(this.minrent / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";
    			}

    			return minRentText;
    		}
    	}
    };
</script>
<style scoped>
    .pricehelp {
    	font-size: 13px;
    	color: #666666;

    }
</style>