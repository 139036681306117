var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      { staticClass: "table pricelist table-hover" },
      _vm._l(_vm.priceArray, function(sg) {
        return _c(
          "tbody",
          [
            sg.service.type != "BOOKING_FEE"
              ? _c("tr", { class: _vm.getClass("mainrow", sg) }, [
                  _c("td", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(sg.service.name) +
                        "\n                    "
                    ),
                    _c("small", [
                      sg.nightRange && sg.nightRange.max == "99"
                        ? _c("span", [
                            _vm._v(
                              "(ab " + _vm._s(sg.nightRange.min) + " Nächten)"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      sg.nightRange && sg.nightRange.max == "3"
                        ? _c("span", [
                            _vm._v("(" + _vm._s(sg.nightRange.min) + " Nächte)")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      sg.guestRange
                        ? _c("span", [
                            _c("span", { attrs: { "vo-msg": "guests" } }, [
                              _vm._v("Gäste")
                            ]),
                            _vm._v(
                              ": " +
                                _vm._s(sg.guestRange.min) +
                                " - " +
                                _vm._s(sg.guestRange.max)
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(_vm.getCalculatedPrice(sg.price, sg.service.type))
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    sg.price && sg.service.calculation != "FLAT"
                      ? _c("div", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(sg.service.calculation) +
                              "\n                        "
                          ),
                          sg.service.perAdult
                            ? _c("small", [
                                _vm._v(" / "),
                                _c("span", { attrs: { "vo-msg": "adult" } }, [
                                  _vm._v("Erwachsener")
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          sg.service.perChild
                            ? _c("small", [
                                _vm._v(" / "),
                                _c("span", { attrs: { "vo-msg": "child" } }, [
                                  _vm._v("Kind")
                                ])
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(sg.lines, function(price) {
              return sg.service.type != "BOOKING_FEE"
                ? _c("tr", { class: _vm.getClass("subrow", sg) }, [
                    _c("td", { staticClass: "subinfo" }, [
                      _c("small", [_vm._v(_vm._s(price.season))]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      price.fromdate
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.getFormattedDate(price.fromdate)) +
                                " - " +
                                _vm._s(_vm.getFormattedDate(price.tilldate))
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.getCalculatedPrice(price.value, sg.service.type)
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.getCalculationType(sg.service.calculation)
                          ) +
                          "\n                "
                      )
                    ])
                  ])
                : _vm._e()
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }