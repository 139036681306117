<template>
    <div>

        <table class="table pricelist table-hover">

            <tbody v-for="sg in priceArray">
                <tr :class="getClass('mainrow', sg)"
                    v-if="sg.service.type != 'BOOKING_FEE'">

                    <td>
                        {{sg.service.name}}
                        <small>
                             <span v-if="sg.nightRange && sg.nightRange.max == '99'">(ab {{sg.nightRange.min}} Nächten)</span>
                          <span v-if="sg.nightRange && sg.nightRange.max == '3'">({{sg.nightRange.min}} Nächte)</span>
                        <span v-if="sg.guestRange"><span vo-msg="guests">Gäste</span>: {{sg.guestRange.min}} - {{sg.guestRange.max}}</span>
                        </small>
                    </td>
                    <td class="text-center">{{getCalculatedPrice(sg.price, sg.service.type)}}</td>
                    <td class="text-right">
                        <div v-if="sg.price && sg.service.calculation != 'FLAT'">
                            {{sg.service.calculation}}
                            <small v-if="sg.service.perAdult"> / <span vo-msg="adult">Erwachsener</span></small>
                            <small v-if="sg.service.perChild"> / <span vo-msg="child">Kind</span></small>
                        </div>

                    </td>
                </tr>
                <tr v-for="price in sg.lines"
                    :class="getClass('subrow', sg)"
                    v-if="sg.service.type != 'BOOKING_FEE'">
                    <td class="subinfo">
                        <small>{{price.season}}</small>
                        <br/>
                        <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                    </td>
                    <td class="text-center">
                        {{getCalculatedPrice(price.value, sg.service.type)}}
                    </td>
                    <td class="text-right">
                        {{getCalculationType(sg.service.calculation)}}
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'price-list',
    	props: {
    		pricelist: String
    	},
    	data: function() {
    		return {
    			priceArray: {},
    			unitId: Number,
    			priceBookingFee: Number,
    			priceFinalCleaning: Number,
    			priceDailyRate: Number
    		};
    	},
    	mounted: function() {
    		var pricelist = JSON.parse(this.pricelist);
    		this.priceArray = pricelist;
    		this.priceDailyRate = 0;

    		//console.log('Pricelist::', pricelist);

    		var i, j;
    		for (i = 0; i < pricelist.length; i++) {
    			if (pricelist[i].service._id && pricelist[i].service._id === 43080) {
    				console.log('Id::', pricelist[i].service._id, 'Price::', pricelist[i].price);
    				this.priceDailyRate = pricelist[i].price;
    			}

    		}
    		//	console.log('years::' + years);
    		/*console.log('this.pricelist::' + this.pricelist); */
    	},
    	methods: {
    		getCalculationType: function(type) {
    			if (type == "NIGHT") {
    				return 'pro Nacht';
    			} else if (type == "FLAT") {
    				return 'pauschal';
    			} else if (type == "DAY") {
    				return 'pro Tag';
    			} else if (type == "WEEK") {
    				return 'pro Woche';
    			} else if (type == "MONTH") {
    				return 'pro Monat';
    			} else if (type == "PERC") {
    				return 'prozentual';
    			} else if (type == "USAGE") {
    				return 'nach Verbrauch';
    			} else if (type == "SCALE") {
    				return 'nach Staffel';
    			}

    		},
    		getClass: function(name, sg) {
    			var myclass = name;

    			if (sg.service._id && sg.nightRange && sg.nightRange.max && sg.nightRange.max == '99') {
    				myclass += ' id' + sg.service._id + '-' + sg.nightRange.max;
    			} else if (sg.service._id && sg.nightRange && sg.nightRange.max && sg.nightRange.max == '3') {
    				myclass += ' id' + sg.service._id + '-' + sg.nightRange.max;
    			} else if (sg.service._id && sg.nightRange) {
    				myclass += ' id' + sg.service._id;
    			} else if (sg.service._id && sg.service._id === 43080) {
    				myclass += ' id' + sg.service._id;
    			}



    			return myclass;
    		},
    		getFormattedDate: function(date) {
    			return DateEx.formatDate(date, 'dd.MM.yy', 'de');
    		},
    		getCalculatedPrice: function(price, type) {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			if (price > 0) {
    				rentPrice = price
    				if (type !== 'RENT') {
    					calcPrice = Number((rentPrice) / 100).toLocaleString("de-DE", {
    						minimumFractionDigits: 2
    					}) + "€";

    				} else if (type === 'RENT') {
    					calcPrice = Number((rentPrice + this.priceDailyRate) / 100).toLocaleString("de-DE", {
    						minimumFractionDigits: 2
    					}) + "€";

    				}
    				/*
    				calcPrice = Number((rentPrice) / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + "€"; */

    				return calcPrice;

    			}

    		},
    		isDateInRange: function(fromdate, year) {
    			if (!fromdate) {
    				return true;
    			}
    			var fromYear = parseInt(fromdate.substring(0, 4));
    			if (fromYear == year) {
    				return true;
    			}
    			return false;
    		}

    	},
    	computed: {

    	}

    };
</script>