<template>
    <div>
        <div :id="getId"
            style="height: 100%; width: 100%; z-index:1;min-height: 250px;">
            <div ref="slot">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import {
    	debounce
    }
    from 'voUtils/tools.js';

    export default {
    	voVueComponent: 'custom-openstreetmap',
    	props: {
    		items: {
    			type: Array,
    			default: function() {
    				return [];
    			}
    		},
    		markerPos: {
    			type: Array,
    			default: function() {
    				return [];
    			}
    		},
    		showMarker: {
    			type: [String, Boolean],
    			default: true
    		},
    		mapId: {
    			type: String,
    			default: 'map'
    		},
    		highlight: Number,
    		initialPos: {
    			type: Array,
    			default: function() {
    				return [10, 54];
    			}
    		},
    		initialZoom: {
    			type: [String, Number],
    			default: 14
    		},
    		cluster: undefined,
    		selected: Object,
    		draggable: {
    			type: [String, Boolean],
    			default: true // true, false, center
    		},
    		scrollWheelZoom: {
    			type: [String, Boolean],
    			default: true // true, false, center
    		},
    		doubleClickZoom: {
    			type: [String, Boolean],
    			default: true // true, false, center
    		},
    	},
    	created: function() {
    		var vc = this;

    		/*this.onMarkerClicked = function() {
    			vc.$emit('update:selected', this.customData);
    		};*/
    	},
    	mounted: function() {
    		this.createMap();
    	},
    	methods: {
    		createMap() {

    				const tiles = window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    					attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Points &copy 2012 LINZ'
    				});
    				this.icon = window.L.divIcon({
    					html: `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 56" width="39" height="56">
	<title>Location (2)-svg</title>
	<style>
		.s0 { fill: #ffffff } 
		.s1 { fill: #fcfcfc } 
		.s2 { fill: #a88351;stroke: #a88351;stroke-width: .1 } 
		.s3 { fill: #a88351 } 
		.s4 { fill: none;stroke: #a88351;stroke-width: .1 } 
	</style>
	<path id="Layer" class="s0" d="m-79.6 10.3c-1.5 0-2.8-1.2-2.8-2.8 0-1.5 1.3-2.8 2.8-2.8 1.6 0 2.8 1.3 2.8 2.8 0 1.6-1.2 2.8-2.8 2.8z"/>
	<path id="Layer" class="s1" d="m38.9 23.7q0 0 0 0-0.1 0.7-0.3 1.7 0 0.4-0.1 0.7c-1.1 6.1-5.2 17.6-19 29.9-4.9-5.2-19.2-19.9-19.4-33.9q0-0.3 0-0.6 0-0.1 0-0.2 0-0.2 0-0.3 0 0 0 0c0.2-11.7 8.8-21 19.4-21 10.8 0 19.5 9.6 19.5 21.5 0 0 0 0.7-0.1 2.2z"/>
	<g id="Layer">
		<path id="Layer" class="s2" d="m29.1 23.6c0.9 1.1-0.7 2.9-0.5 2.6 0.2-0.2 0.6-1 0.5-2.1-0.1-1-1.5-1.3-3.1-0.8-1.1 0.4-1.9 1.3-2.3 1.8q-0.4 1.1-0.8 2.1c-0.3 1.3-0.6 2.5-0.8 3.8h-0.5q0.4-2 1-3.9c0.4-1.2 0.9-2.5 1.6-3.6q0.3-0.3 0.5-0.6c-0.1-0.4-0.3-2 0.3-3.7 0.6-2 1.1-2.8 1.2-3.4 0.1-0.5 0.1-1.1 0.1-1.1 0 0 0.2 0.6 0.1 1.1-0.1 0.6-0.2 0.7-0.4 1.4-0.2 0.6-0.5 1.5-0.7 2.2-0.1 0.8-0.2 2.5-0.2 2.9q0.8-1 1.7-1.8c1-0.9 2.1-1.6 3.3-2.2-1.2 0.6-2.2 1.4-3.2 2.3-0.9 0.9-1.8 1.9-2.4 3q-0.1 0.3-0.3 0.5c0.6-0.5 1.5-1.1 2-1.3 0.7-0.2 1.9-0.4 2.9 0.8z"/>
		<path id="Layer" class="s3" d="m20.3 19.7q0.1 2.8 0.1 5.6 0 2.8-0.1 5.6h-0.5q0.2-2.8 0.2-5.6 0-0.8 0-1.7c-0.2-0.6-1-2.3-1.1-3.6-0.1-1.7 0.4-1.9 0.4-3.1 0-1.3-0.4-2.4-0.4-2.4 0 0 0.9 1.8 0.6 3-0.4 1.3-0.3 2.2-0.1 3 0.1 0.5 0.1 0.7 0.6 2.4q0-1.6 0-3.2-0.1-2.8-0.4-5.6-0.2-1.4-0.5-2.8c-0.2-0.9-0.5-1.9-0.9-2.7 0.4 0.8 0.7 1.7 0.9 2.7q0.4 1.3 0.6 2.7 0.4 2.9 0.6 5.7z"/>
		<path id="Layer" class="s4" d="m20.3 19.7q0.1 2.8 0.1 5.6 0 2.8-0.1 5.6h-0.5q0.2-2.8 0.2-5.6 0-0.8 0-1.7c-0.2-0.6-1-2.3-1.1-3.6-0.1-1.7 0.4-1.9 0.4-3.1 0-1.3-0.4-2.4-0.4-2.4 0 0 0.9 1.8 0.6 3-0.4 1.3-0.3 2.2-0.1 3 0.1 0.5 0.1 0.7 0.6 2.4q0-1.6 0-3.2-0.1-2.8-0.4-5.6-0.2-1.4-0.5-2.8c-0.2-0.9-0.5-1.9-0.9-2.7 0.4 0.8 0.7 1.7 0.9 2.7q0.4 1.3 0.6 2.7 0.4 2.9 0.6 5.7z"/>
		<path id="Layer" class="s3" d="m16.3 26.4q0.6 2.2 0.9 4.6h-0.5q-0.3-2.3-0.7-4.6-0.4-2-1-4 0 0 0 0c-0.2-0.1-2.7-3-3.1-3.6-0.5-0.7-2.1-2.7-2.1-2.7 0 0 0.4 0.5 0.9 1 0.6 0.6 1.9 2 2.5 2.7 0.5 0.5 1.3 1.6 1.7 2.1q0 0 0 0c-0.4-1.6-0.9-3-1.5-4.5-0.6-1.4-1.3-2.9-2.5-4 1.2 1.1 1.9 2.5 2.6 4q0 0 0 0.1c-0.1-0.8-0.4-2.3-0.6-2.7-0.2-0.7-1.4-3.8-1.4-3.8 0 0 1.7 3.2 1.9 3.9 0.1 0.7 0.6 3.1 0.6 3.7q0.6 1.5 1.1 3.2 0.7 2.2 1.2 4.6z"/>
		<path id="Layer" class="s4" d="m16.3 26.4q0.6 2.2 0.9 4.6h-0.5q-0.3-2.3-0.7-4.6-0.4-2-1-4 0 0 0 0c-0.2-0.1-2.7-3-3.1-3.6-0.5-0.7-2.1-2.7-2.1-2.7 0 0 0.4 0.5 0.9 1 0.6 0.6 1.9 2 2.5 2.7 0.5 0.5 1.3 1.6 1.7 2.1q0 0 0 0c-0.4-1.6-0.9-3-1.5-4.5-0.6-1.4-1.3-2.9-2.5-4 1.2 1.1 1.9 2.5 2.6 4q0 0 0 0.1c-0.1-0.8-0.4-2.3-0.6-2.7-0.2-0.7-1.4-3.8-1.4-3.8 0 0 1.7 3.2 1.9 3.9 0.1 0.7 0.6 3.1 0.6 3.7q0.6 1.5 1.1 3.2 0.7 2.2 1.2 4.6z"/>
		<path id="Layer" class="s2" d="m15.5 28.5q0.2 1.2 0.3 2.4h-0.5c0-1.1-0.1-1.4-0.2-2.4q0-0.1-0.1-0.3c-0.3-0.5-1-1.5-1.6-2.2-1-1-1.4-2.9-1.4-2.9 0 0 0.6 1.6 1.6 2.7 0.7 0.6 1.1 1.3 1.3 1.7q-0.1-0.7-0.3-1.4-0.3-1.2-0.9-2.3c-0.3-0.8-0.8-1.5-1.3-2.1 0.6 0.6 1.1 1.3 1.4 2q0.6 1.1 1 2.3 0.4 1.2 0.7 2.5z"/>
		<path id="Layer" class="s4" d="m15.5 28.5q0.2 1.2 0.3 2.4h-0.5c0-1.1-0.1-1.4-0.2-2.4q0-0.1-0.1-0.3c-0.3-0.5-1-1.5-1.6-2.2-1-1-1.4-2.9-1.4-2.9 0 0 0.6 1.6 1.6 2.7 0.7 0.6 1.1 1.3 1.3 1.7q-0.1-0.7-0.3-1.4-0.3-1.2-0.9-2.3c-0.3-0.8-0.8-1.5-1.3-2.1 0.6 0.6 1.1 1.3 1.4 2q0.6 1.1 1 2.3 0.4 1.2 0.7 2.5z"/>
		<path id="Layer" class="s2" d="m17 20q0 1.6 0.1 3.2 0.1 2 0.5 3.9 0.3 2 0.9 3.8h-0.5c-0.4-1.2-0.6-2.5-0.8-3.7q-0.3-2-0.4-4 0-1.9 0.1-3.7 0-0.1 0-0.1c-0.5-1.9-1-3.8-1.7-5.7q-0.4-1.1-1-2.2 0-0.1-0.2-0.2c-0.1-0.2-0.4-0.4-0.5-0.4-0.2-0.1-0.6-0.7-1-1.3-0.5-0.9-0.8-1.5-0.5-1.7 0.6-0.4 1.2 1 1.5 1.5 0.3 0.4 0.6 1 0.6 1.3 0 0 0 0.2 0.1 0.3q0 0.1 0 0.2 0.1 0.2 0.1 0.3 0.6 1.1 1.1 2.2c0.6 1.7 1.1 3.4 1.6 5.1q0.2-1.8 0.6-3.6-0.4 1.9-0.5 3.9 0 0.1 0.1 0.3 0.7 2.8 1.2 5.7 0.5 2.9 0.9 5.9h-0.5q-0.3-2.9-0.8-5.8-0.4-2.6-1-5.2z"/>
		<path id="Layer" class="s4" d="m17 20q0 1.6 0.1 3.2 0.1 2 0.5 3.9 0.3 2 0.9 3.8h-0.5c-0.4-1.2-0.6-2.5-0.8-3.7q-0.3-2-0.4-4 0-1.9 0.1-3.7 0-0.1 0-0.1c-0.5-1.9-1-3.8-1.7-5.7q-0.4-1.1-1-2.2 0-0.1-0.2-0.2c-0.1-0.2-0.4-0.4-0.5-0.4-0.2-0.1-0.6-0.7-1-1.3-0.5-0.9-0.8-1.5-0.5-1.7 0.6-0.4 1.2 1 1.5 1.5 0.3 0.4 0.6 1 0.6 1.3 0 0 0 0.2 0.1 0.3q0 0.1 0 0.2 0.1 0.2 0.1 0.3 0.6 1.1 1.1 2.2c0.6 1.7 1.1 3.4 1.6 5.1q0.2-1.8 0.6-3.6-0.4 1.9-0.5 3.9 0 0.1 0.1 0.3 0.7 2.8 1.2 5.7 0.5 2.9 0.9 5.9h-0.5q-0.3-2.9-0.8-5.8-0.4-2.6-1-5.2z"/>
		<path id="Layer" class="s3" d="m22.3 20q-0.1 0.4-0.2 0.9c0.4-0.7 1.4-2.6 1.7-3.1 0.3-0.5 2-3.2 2-3.2 0 0-2.6 5.4-3 6-0.2 0.3-0.6 0.6-0.7 0.8q-0.3 2-0.5 4.1-0.3 2.7-0.3 5.5h-0.5q0.1-2.8 0.4-5.6 0.3-2.7 0.8-5.5 0.3-1.4 0.6-2.8c-0.1-0.4-0.4-1.5-0.6-2.1-0.3-0.8-0.1-1.5 0.1-2.2 0.2-0.8 0-3.1 0-3.1 0 0 0.4 0.5 0.5 1.1 0 0.7-0.3 1.5-0.3 2.6 0 0.8 0.3 2.7 0.4 3.3q0.3-1.1 0.6-2.2 0.5-1.9 1.2-3.6 0.1-0.2 0.2-0.3 0-0.2 0.1-0.4 0-0.1 0-0.1c0-0.3 0-0.4 0-0.7-0.1-0.3 0.5-1.5 0.9-2.4 0.4-1 0.7-1.5 1.1-1.3 0.5 0.1 0.4 0.9-0.3 2.1-0.6 1.3-0.7 1.6-1 1.8-0.1 0.1-0.2 0.2-0.3 0.4q-0.1 0-0.2 0.1c-0.1 0.2-0.2 0.4-0.3 0.6-0.1 0.2-0.9 2.5-1.3 3.8q-0.7 2.7-1.1 5.5z"/>
		<path id="Layer" class="s4" d="m22.3 20q-0.1 0.4-0.2 0.9c0.4-0.7 1.4-2.6 1.7-3.1 0.3-0.5 2-3.2 2-3.2 0 0-2.6 5.4-3 6-0.2 0.3-0.6 0.6-0.7 0.8q-0.3 2-0.5 4.1-0.3 2.7-0.3 5.5h-0.5q0.1-2.8 0.4-5.6 0.3-2.7 0.8-5.5 0.3-1.4 0.6-2.8c-0.1-0.4-0.4-1.5-0.6-2.1-0.3-0.8-0.1-1.5 0.1-2.2 0.2-0.8 0-3.1 0-3.1 0 0 0.4 0.5 0.5 1.1 0 0.7-0.3 1.5-0.3 2.6 0 0.8 0.3 2.7 0.4 3.3q0.3-1.1 0.6-2.2 0.5-1.9 1.2-3.6 0.1-0.2 0.2-0.3 0-0.2 0.1-0.4 0-0.1 0-0.1c0-0.3 0-0.4 0-0.7-0.1-0.3 0.5-1.5 0.9-2.4 0.4-1 0.7-1.5 1.1-1.3 0.5 0.1 0.4 0.9-0.3 2.1-0.6 1.3-0.7 1.6-1 1.8-0.1 0.1-0.2 0.2-0.3 0.4q-0.1 0-0.2 0.1c-0.1 0.2-0.2 0.4-0.3 0.6-0.1 0.2-0.9 2.5-1.3 3.8q-0.7 2.7-1.1 5.5z"/>
	</g>
</svg>`,
    					className: "openstreet-marker",
    					iconSize: [36, 36],
    					popupAnchor: [0, -15]
    				})
    				const map = window.L.map(this.getId, {
    					center: new window.L.LatLng(this.composeGeolocation(this.initialPos[1]), this.composeGeolocation(this.initialPos[0]), {
    						icon: this.icon
    					}),
    					fullscreenControl: true,
    					zoom: this.initialZoom,
    					dragging: this.draggable,
    					doubleClickZoom: this.doubleClickZoom,
    					scrollWheelZoom: this.scrollWheelZoom,
    					attributionControl: false,
    					layers: [tiles]
    				});
    				map.on('popupopen', function(e) {
    					$('.leaflet-popup-close-button').removeAttr('href');
    					var px = map.project(e.target._popup._latlng);
    					px.y -= e.target._popup._container.clientHeight / 2 + 100;
    					map.panTo(map.unproject(px), {
    						animate: false
    					});
    				});
    				this.markers = [];
    				this.map = map;
    				if (this.markerPos && this.markerPos.length > 0) {
    					this.updateSingleMarker();
    				}
    			},
    			updateSingleMarker() {
    				if (this.map) {
    					this.deleteMarkers();

    					const marker = new window.L.marker(new window.L.LatLng(this.composeGeolocation(this.markerPos[1]), this.composeGeolocation(this.markerPos[0])), {
    						icon: this.icon
    					});
    					if (this.showMarker) {
    						marker.addTo(this.map);
    					}
    					this.map.panTo(new window.L.LatLng(this.composeGeolocation(this.markerPos[1]), this.composeGeolocation(this.markerPos[0])));
    				}

    			},
    			deleteMarkers() {
    				if (this.markers) {
    					this.map.removeLayer(this.markers);
    				}
    				if (this.clusterObject) {
    					this.map.removeLayer(this.clusterObject);
    				}
    				this.markers = [];
    			},
    			updateMarkers() {
    				if (this.map) {
    					this.deleteMarkers();
    					var clusters = window.L.markerClusterGroup({
    						animate: false
    					});
    					var markers = [];

    					if (this.items.length > 0) {
    						for (var i = 0; i < this.items.length; i++) {
    							var unit = this.items[i];

    							if (!unit) {
    								continue;
    							}

    							let marker;
    							if (unit.loc) {
    								marker = new window.L.marker(new window.L.LatLng(this.composeGeolocation(unit.loc.coordinates[1]), this.composeGeolocation(unit.loc.coordinates[0])), {
    									icon: this.icon,
    									customData: {
    										info: unit
    									}
    								}).setBouncingOptions({
    									bounceHeight: 30,
    									bounceSpeed: 54,
    									exclusive: true,
    								}).on('click', () => {
    									this.$emit('update:selected', marker.options.customData.info);
    								}).bindPopup(this.$refs.slot, {
    									minWidth: 320,
    									autopan: true
    								});

    								if (i === 0) {
    									this.map.flyTo(marker.getLatLng());
    								}
    								if (this.cluster) {
    									clusters.addLayer(marker);
    								} else {
    									marker.addTo(this.map)
    								}
    								this.markers.push(marker);
    								marker.getPopup().on(('remove'), () => {
    									this.$emit('update:selected', {});
    								});
    							} else if (Array.isArray(unit)) {
    								for (let i = 2; i < unit.length; i++) {
    									marker = new window.L.marker(new window.L.LatLng(this.composeGeolocation(unit[1]), this.composeGeolocation(unit[0])), {
    										icon: this.icon,
    										customData: {
    											_id: unit[i],
    											lazy: true,
    											info: unit
    										}
    									}).setBouncingOptions({
    										bounceHeight: 30,
    										bounceSpeed: 54,
    										exclusive: true,
    									}).on('click', () => {
    										this.$emit('update:selected', marker.options.customData);
    									}).bindPopup(this.$refs.slot, {
    										minWidth: 320,
    										autopan: true
    									});
    									marker.getPopup().on(('remove'), () => {
    										this.$emit('update:selected', {});
    									});
    									this.markers.push(marker);
    									clusters.addLayer(marker);
    								}
    							}
    						}
    					}


    					if (this.cluster) {
    						this.clusterObject = clusters;
    						this.map.addLayer(clusters)
    					}
    					this.map.invalidateSize(true);
    				}
    			},
    			composeGeolocation(input) {
    				return input.toFixed(6) + Math.floor(Math.random() * 4);
    			},
    			unHighlightMarker(m) {
    				if (this.highlightedMarker) {
    					if (this.highlightedMarker._icon) {
    						this.highlightedMarker._icon.classList.remove("animate-marker");
    					}
    					this.highlightedMarker = undefined;
    				}
    			},
    			highlightMarker: function(m) {
    				this.highlightedMarker = m;
    				this.map.once('moveend zoomend', () => {
    					const parent = this.clusterObject.getVisibleParent(m);
    					if (parent && parent.spiderfy) {
    						parent.spiderfy();
    					}
    					if ((this.highlightedMarker === m || !this.highlightedMarker) && m._icon) {
    						m._icon.classList.add("animate-marker");
    						m.bounce(2);
    					}
    					if (!this.highlightedMarker === m) {
    						this.unHighlightMarker(this.highlightedMarker);
    					}
    				});
    			},

    			findMarkerById(id) {
    				if (this.markers) {
    					for (let m of this.markers) {
    						if (m.options && m.options.customData && ((m.options.customData.info && m.options.customData.info._id == id) || (m.options.customData._id == id))) {
    							return m;
    						}
    					}
    				}
    			}
    	},
    	watch: {
    		items: {
    			deep: true,
    			handler: function(nv) {
    				this.updateMarkers();
    			}
    		},
    		highlight: function(id) {
    			this.unHighlightMarker(this.highlightedMarker);

    			if (id) {
    				let m = this.findMarkerById(id);

    				if (m) {
    					if (!m.getPopup().isOpen()) {
    						this.map.closePopup();
    					}
    					this.map.flyTo(m.getLatLng(), Math.max(this.map.getZoom(), 13), {
    						animate: true,
    						duration: 0.4
    					});
    					this.highlightMarker(m);

    				}
    			}
    		},
    		markerPos: {
    			deep: true,
    			handler: function(val, oldVal) {
    				this.updateSingleMarker();
    			}
    		},
    		selected: function(nv) {
    			this.unHighlightMarker(this.highlightedMarker);
    			if (nv._id) {
    				var m = this.findMarkerById(nv._id);
    				if (m) {
    					m.openPopup();
    				} else {
    					this.map.closePopup();
    				}
    			} else {
    				this.map.closePopup();
    			}
    		}

    	},
    	computed: {
    		getId: function() {
    			return this.mapId;
    		}
    	}
    };
</script>